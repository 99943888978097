import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Column, Notification, Table, Button, Help } from 'rbx';

import { Loading } from '../../../components';
import useResumes from '../../../hooks/useResumes';
import useUser from '../../../hooks/useUser';

function Resumes() {
  const [selected, setSelected] = useState(null);
  const {
    resumes,
    isLoading,
    downloadResume,
    listResumes,
    lastResumeId,
    isDownloading,
  } = useResumes();
  const { user } = useUser();
  useEffect(() => {
    listResumes({
      userId: user.id,
      limit: 10,
      resumeId: lastResumeId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  if (!user) return null;

  const clickHandler = ({ target }) => {
    const resumeId = target.dataset.value;
    setSelected(resumeId);
    downloadResume(user.id, resumeId, user.gender);
  };

  return (
    <Column.Group>
      <Column size="full">
        {!resumes.length && !isLoading ? (
          <Notification color="info">
            There are no resumes available for this user
          </Notification>
        ) : (
          <>
            <Table fullwidth striped hoverable>
              <Table.Head>
                <Table.Row>
                  <th>Sr.no</th>
                  <th>ID</th>
                  <th>Template</th>
                  <th>Created on</th>
                  <th>Updated on</th>
                  <th>Archived</th>
                  <th>Actions</th>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {resumes.map((resume, index) => (
                  <Table.Row key={index + 1}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell title={resume.resumeId}>
                      {resume.resumeId.split('-')[0]}
                    </Table.Cell>
                    <Table.Cell>{resume.settings.template}</Table.Cell>
                    <Table.Cell>
                      {typeof resume.createdAt === 'object'
                        ? 'Unknown'
                        : format(new Date(resume.createdAt), 'do MMM yyyy')}
                    </Table.Cell>
                    <Table.Cell>
                      {resume.updatedAt
                        ? format(new Date(resume.updatedAt), 'do MMM yyyy')
                        : 'Unknown'}
                    </Table.Cell>
                    <Table.Cell>
                      {resume?.archivedDate && (
                        <Help
                          color="danger"
                          tooltip={format(
                            new Date(resume?.archivedDate),
                            'do MMM yyyy',
                          )}
                        >
                          Archived
                        </Help>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        color="link"
                        size="small"
                        data-value={resume.resumeId}
                        onClick={clickHandler}
                        state={
                          isDownloading && selected === resume.resumeId
                            ? 'loading'
                            : ''
                        }
                        disabled={isDownloading && selected === resume.resumeId}
                      >
                        Download
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
              {isLoading && <Loading />}
            </Table>
            <Button
              color="link"
              onClick={() => {
                listResumes({
                  userId: user.id,
                  limit: 10,
                  resumeId: lastResumeId,
                });
              }}
            >
              Load More
            </Button>
          </>
        )}
      </Column>
    </Column.Group>
  );
}

export default Resumes;
