import * as API from '../lib/api';

import React, { createContext, useContext, useState } from 'react';

import PropTypes from 'prop-types';
import {
  getVatRateForEuropeanCountries,
  getSiteURLString,
} from '../lib/helper';
const InvoiceContext = createContext();

// Provider component that wraps your app and makes user object
// available to any child component that calls useUser().
export function InvoiceProvider({ children, value }) {
  const invoice = useInvoiceProvider(value);
  return (
    <InvoiceContext.Provider value={invoice}>
      {children}
    </InvoiceContext.Provider>
  );
}

InvoiceProvider.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
};

// Provider hook that creates user object and handles state
function useInvoiceProvider(_userId) {
  const [invoice, setInvoice] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);

  // Gets a invoice by user Id
  async function getInvoiceByUserId(id) {
    setIsLoading(true);
    setError(null);
    try {
      const invoice = await API.listInvoice(id);
      setInvoice(invoice?.Items);
    } catch (e) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  // Gets a invoice by email Id
  async function getInvoiceByEmailId(emailId) {
    setIsLoading(true);
    setError(null);
    try {
      const invoice = await API.listInvoiceByEmailId(emailId);
      setInvoice(invoice);
    } catch (e) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function getCompanyData(invoice) {
    try {
      const solidKukarioDomains = [
        'modelos-de-curriculo',
        'modellocv',
        'cv-hebrew',
        'cvforma',
        'cvtoolspro',
      ];
      const domain = invoice?.domain?.toLowerCase();
      let companyName = solidKukarioDomains.includes(domain)
        ? 'KUKARIO LIMITED'
        : 'FEF COMPANY';
      const companyInfo = await API.getCompanyInfo(companyName);
      let vatInfo = getVatRateForEuropeanCountries.find(
        el => el.country.toLowerCase() === invoice?.country?.toLowerCase(),
      );
      let unitAmt = invoice?.amount / 100;
      let vat = 0;
      if (vatInfo) {
        unitAmt = (invoice?.amount / 100 / (1 + vatInfo?.rate / 100)).toFixed(
          2,
        );
        vat = (invoice?.amount / 100 - unitAmt).toFixed(2);
      }
      const timeDifference = Math.abs(
        new Date(invoice?.billingPeriodEnd) - new Date(invoice?.createdAt),
      );
      const dayDiff = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const plan =
        dayDiff > 15
          ? 'Monthly Access'
          : !!invoice.plan
          ? invoice.plan.trim()?.replace('EXP 3', '')
          : null;
      let invoiceData = {
        websiteName: getSiteURLString(invoice?.domain) || null,
        compName: !!companyInfo.Name ? companyInfo.Name.trim() : null,
        compAdd: !!companyInfo.Address ? companyInfo.Address.trim() : null,
        compCity: !!companyInfo.City ? companyInfo.City.trim() : null,
        compZipCode: !!companyInfo['Zip Code']
          ? companyInfo['Zip Code'].trim()
          : null,
        CompCountry: !!companyInfo.Country ? companyInfo.Country.trim() : null,
        CompVatNo: !!companyInfo['VAT/tax no']
          ? companyInfo['VAT/tax no'].trim()
          : null,
        compEmail: !!companyInfo['director email']
          ? companyInfo['director email'].trim()
          : null,
        invoiceDate: invoice?.createdAt
          ? new Date(invoice?.createdAt.split('T')[0]).toLocaleDateString()
          : new Date().toLocaleDateString(),
        invoiceNo: invoice.createdAt
          ? `${new Date(invoice?.createdAt.split('T')[0])
              .toLocaleDateString()
              .replace(/\//g, '')}${Math.floor(1000 + Math.random() * 9000)}`
          : `${new Date().toLocaleDateString().replace(/\//g, '')}${Math.floor(
              1000 + Math.random() * 9000,
            )}`,
        custName:
          !!invoice.firstName && !!invoice.lastName
            ? invoice.firstName.trim() + ' ' + invoice.lastName.trim()
            : null,
        custAdd: !!invoice.address ? invoice.address.trim() : null,
        custCity: !!invoice.city ? invoice.city.trim() : null,
        custZipCode: !!invoice.postalCode ? invoice.postalCode.trim() : null,
        custCountry: !!invoice.country ? invoice.country.trim() : null,
        custEmail: !!invoice.email ? invoice.email.trim() : null,
        custPlan: plan,
        custAmt: !!invoice.amount ? invoice.amount / 100 : null,
        custCurrency: !!invoice.currency ? invoice.currency : null,
        unitAmt,
        vat,
      };
      setInvoiceData(invoiceData);
      return invoiceData;
    } catch (e) {
      console.log('error : ', e);
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  // Return the invoice object and invoice methods
  return {
    invoice,
    setInvoice,
    invoiceData,
    setInvoiceData,
    getInvoiceByUserId,
    getInvoiceByEmailId,
    getCompanyData,
    isLoading,
    error,
  };
}

// Hook for child components to get the user object
// and re-render when it changes.
const useInvoice = () => useContext(InvoiceContext);
export default useInvoice;
