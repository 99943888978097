import * as API from '../lib/api';

import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const ResumesContext = createContext();

// Provider component that wraps your app and makes user object
// available to any child component that calls useResumes().
export function ResumesProvider({ children, value }) {
  const resumes = useResumesProvider(value);
  return (
    <ResumesContext.Provider value={resumes}>
      {children}
    </ResumesContext.Provider>
  );
}

ResumesProvider.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
};

function useResumesProvider(_resume) {
  const [resumes, setResumes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [error, setError] = useState(null);
  const [lastResumeId, setLastResumeId] = useState(null);

  // list resumes for user
  async function listResumes(params) {
    setIsLoading(true);
    setError(null);
    try {
      // list resumes
      const newResumes = await API.listResumes(params);
      if (newResumes.length) {
        setLastResumeId(newResumes[newResumes.length - 1].resumeId);
      }
      newResumes.sort((a, b) => {
        a = typeof a.createdAt === 'object' ? null : new Date(a.createdAt);
        b = typeof b.createdAt === 'object' ? null : new Date(b.createdAt);
        if (a === b) return 0;
        if (a > b) {
          return -1;
        } else {
          return 1;
        }
      });
      setResumes(prev => [...prev, ...newResumes]);
    } catch (e) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function downloadResume(userId, resumeId, gender = 'male') {
    setIsDownloading(true);
    setError(null);
    try {
      const resume = await API.getResume(userId, resumeId);
      if (resume) {
        const html = await API.getDocumentHtml({ file: resume, gender });
        if (html) {
          const {
            settings: { template, color },
          } = resume;
          const buffer = await API.exportDocument({
            html,
            template,
            color,
            gender,
          });
          const file = new Blob([buffer], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      } else {
        throw new Error('Resume not found');
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setIsDownloading(false);
    }
  }

  return {
    resumes,
    isLoading,
    isDownloading,
    setResumes,
    listResumes,
    lastResumeId,
    setLastResumeId,
    downloadResume,
    error,
  };
}

// Hook for child components to get the user object
// and re-render when it changes.
const useResumes = () => useContext(ResumesContext);
export default useResumes;
